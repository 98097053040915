import React, { useCallback, useState } from "react"
import { Formik, Field, ErrorMessage } from 'formik';
import {css} from "@emotion/react";
import FormStyle from "./FormStyle"
import Title from "../../atoms/Title/Title"

export default function ComoForm({color,titulo1, titulo2}) {

  const formCss = css`
      & > div{
        display: flex;
        flex-wrap: wrap;
      }
      @media (max-width: 767px) {
        padding-right:calc(100vw * (calc(20 / var(--width_base))));
        padding-left:calc(100vw * (calc(20 / var(--width_base))));
      }
    & .form-column{
      float: left;
      flex-shrink: 0;
      width: calc(50% - calc(100vw * (calc(53 / var(--width_base)))));
      @media (max-width: 767px){
          width: 100%;
      }
    }

    & .form-column:nth-of-type(2n){
      margin-left: calc(100vw * (calc(106 / var(--width_base))));
      @media (max-width: 767px){
        margin-top: calc(100vw * (calc(40 / var(--width_base))));
          margin-left: initial;
      }
    }
    
    & .form-control{
      border: none;
      color: #616161;
      &::placeholder{
        color: #616161;
      }
    }
    & .form-group{
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  const formAceptCss = css`
    color: #616161 ;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    margin-top: calc(100vw * (calc(15 / var(--width_base))));
    display: inline-block;
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
      margin-left: calc(100vw * (calc(22 / var(--width_base))));
      }
    & input{
      @media (max-width: 767px) {
        margin-left: calc(100vw * (calc(-22 / var(--width_base))));
      }
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(24 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    font-family: sans-serif;
    color: #616161;
  `;

  const submitCss = css`
    color: white;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    padding: calc(100vw * (calc(8 / var(--width_base)))) calc(100vw * (calc(40 / var(--width_base))));
    font-family: "Source Sans Pro", sans-serif;
    font-style: italic;
    background-color: #8cafa8 !important;
    margin-left: auto;
    margin-top: calc(100vw * (calc(30 / var(--width_base))));
    float: right;
    text-transform: uppercase;
  `;

  const labelRatingCss = css`
    &.is-active .fa-star{
      color: #8cafa8;
    }
    & .fa-star{
      color: #e6e6e6;
      margin-left: calc(100vw * (calc(2 / var(--width_base))));
      margin-right: calc(100vw * (calc(2 / var(--width_base))));
    }
    & input{
      display: none;
    }
  `;

  const ratingItemCss = css`
    display: flex;
    justify-content: space-between;
    &:not(:last-of-type){
      margin-bottom: calc(100vw * (calc(8 / var(--width_base))));
    }
  `;

  const subtilteCss = css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
  `;

  const ratingCss = css`
    background-color: white;
    padding: calc(100vw * (calc(10 / var(--width_base))));
    color: #616161;
    font-style: italic;
  `;

  // LOGICA

  const [envio, setEnvio] = useState("");
  const [sala, setSala] = useState("");
  const [cocina, setCocina] = useState("");
  const [dorm, setDorm] = useState("");
  const [banio, setBanio] = useState("");
  const [terraza, setTerraza] = useState("");
  const [estudio, setEstudio] = useState("");

  const onChangeSala = useCallback((event) => {
    const valorSelect = event?.target?.value;
    setSala(valorSelect);
  }, []);

  const onChangeCocina = useCallback((event) => {
    const valorSelect = event?.target?.value;
    setCocina(valorSelect);
  }, []);
  const onChangeDorm = useCallback((event) => {
    const valorSelect = event?.target?.value;
    setDorm(valorSelect);
  }, []);
  const onChangeBanio = useCallback((event) => {
    const valorSelect = event?.target?.value;
    setBanio(valorSelect);
  }, []);
  const onChangeTerraza = useCallback((event) => {
    const valorSelect = event?.target?.value;
    setTerraza(valorSelect);
  }, []);
  const onChangeEstudio = useCallback((event) => {
    const valorSelect = event?.target?.value;
    setEstudio(valorSelect);
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          Distrito: '',
          ZonaDePrefencia: '',
          MetrosCuadrados: '',
          NroDormitorios: '',
          Precio: '',
          NombresYApellidos: '',
          Edad: '',
          Email: '',
          Celular: '',
          DistritoDeResidencia: '',
          Acepto_condiciones: '',
          Acepto_condiciones2: ''}}
        validate={values => {
          const errors = {};

          if (!values.Distrito) errors.Distrito = 'Campo requerido';
          if (!values.ZonaDePrefencia) errors.ZonaDePrefencia = 'Campo requerido';
          if (!values.MetrosCuadrados) errors.MetrosCuadrados = 'Campo requerido';
          if (!values.NroDormitorios) errors.NroDormitorios = 'Campo requerido';
          if (!values.Precio) errors.Precio = 'Campo requerido';
          if (!values.NombresYApellidos) errors.NombresYApellidos = 'Campo requerido';
          if (!values.Edad) errors.Edad = 'Campo requerido';
          if (!values.Email) errors.Email = 'Campo requerido';
          else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) errors.Email = 'Correo Invalido';
          if (!values.Celular) errors.Celular = 'Campo requerido';
          if (!values.DistritoDeResidencia) errors.DistritoDeResidencia = 'Campo requerido';
          if (!values.Acepto_condiciones) errors.Acepto_condiciones = 'Campo requerido';
          if (!values.Acepto_condiciones2) errors.Acepto_condiciones2 = 'Campo requerido';
          return errors;
        }}
        onSubmit={(values, { setSubmitting,resetForm }) => {
          try{

            const formData = new FormData();
            formData.append("_wpcf7_unit_tag", "wpcf7-f11087-o1")
            formData.append('Distrito', values.Distrito);
            formData.append('ZonaDePrefencia', values.ZonaDePrefencia);
            formData.append('MetrosCuadrados', values.MetrosCuadrados);
            formData.append('NroDormitorios', values.NroDormitorios);
            formData.append('Precio', values.Precio);
            formData.append('NombresYApellidos', values.NombresYApellidos);
            formData.append('Edad', values.Edad);
            formData.append('Email', values.Email);
            formData.append('Celular', values.Celular);
            formData.append('SalaComedor', values.SalaComedor);
            formData.append('Cocina', values.Cocina);
            formData.append('Dormitorio', values.Dormitorio);
            formData.append('Banio', values.Banio);
            formData.append('Terraza', values.Terraza);
            formData.append('Estudio', values.Estudio);
            formData.append('DistritoDeResidencia', values.DistritoDeResidencia);

            fetch(`${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/11087/feedback`, {
              method: 'POST',
              body: formData
            })
              .then(res => res.json())
              .then(json => {
                if(json.status === "mail_sent"){
                  setEnvio(true)
                  setSubmitting(false);
                  resetForm();
                }
                else{
                  setEnvio(false)
                }
                setSala("");
                setCocina("");
                setDorm("");
                setBanio("");
                setTerraza("")
                setEstudio("");
              })

          }catch (error) {
            console.error(error);
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <FormStyle styleCss={formCss}>
            <div className="form-column">
              <Title text={titulo1} styleCss={titleCss}/>
              <div className="form-group">
                <Field className="form-control" type="text" name="Distrito" placeholder="Distrito" />
                <ErrorMessage className="form-error" name="Distrito" component="div" />
              </div>
              <div className="form-group">
                <Field className="form-control" type="text" name="ZonaDePrefencia" placeholder="Zona de preferencia*" />
                <ErrorMessage className="form-error" name="ZonaDePrefencia" component="div" />
              </div>
              <div className="form-group">
                <Field name="MetrosCuadrados" as="select" className="form-control">
                  <option value="">M²</option>
                  <option value="30 - 80 M²">30 - 80 M²</option>
                  <option value="80 - 130 M²">80 - 130 M²</option>
                  <option value="130 - 180 M²">130 - 180 M²</option>
                  <option value="180 - 230 M²">180 - 230 M²</option>
                  <option value="230 a más">230 a más</option>
                </Field>
                <ErrorMessage className="form-error" name="MetrosCuadrados" component="div" />
              </div>
              <div className="form-group">
                <Field name="NroDormitorios" as="select" className="form-control">
                  <option value="">N° de dormitorios</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4 a más ...">4 a más ...</option>
                </Field>
                <ErrorMessage className="form-error" name="NroDormitorios" component="div" />
              </div>
              <div className="form-group">
                <Field name="Precio" as="select" className="form-control">
                  <option value="">Precio</option>
                  <option value="US$. 60,000 - US$. 120,000">US$. 60,000 - US$. 120,000</option>
                  <option value="US$. 120,000 - US$. 200,000">US$. 120,000 - US$. 200,000</option>
                  <option value="US$. 200,000 - US$. 300,000">US$. 200,000 - US$. 300,000</option>
                  <option value="US$. 300,000 - US$. 450,000">US$. 300,000 - US$. 450,000</option>
                  <option value="US$. 450,000 - US$. 650,000">US$. 450,000 - US$. 650,000</option>
                  <option value="US$. 650,000 a más">US$. 650,000 a más</option>
                </Field>
                <ErrorMessage className="form-error" name="NroDormitorios" component="div" />
              </div>
              <div css={ratingCss}>
                <div css={subtilteCss}>Nivel de importancia por ambientes</div>
                <div css={ratingItemCss}>
                  <span>• Sala comedor</span>
                  <div>
                    <label htmlFor="sala1" css={labelRatingCss} className={(1 <= sala ) ? "is-active" : ""}><Field className="form-control" type="radio" name="SalaComedor" value="1" id="sala1" onChange={onChangeSala}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="sala2" css={labelRatingCss} className={(2 <= sala ) ? "is-active" : ""}><Field className="form-control" type="radio" name="SalaComedor" value="2" id="sala2" onChange={onChangeSala}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="sala3" css={labelRatingCss} className={(3 <= sala ) ? "is-active" : ""}><Field className="form-control" type="radio" name="SalaComedor" value="3" id="sala3" onChange={onChangeSala}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="sala4" css={labelRatingCss} className={(4 <= sala ) ? "is-active" : ""}><Field className="form-control" type="radio" name="SalaComedor" value="4" id="sala4" onChange={onChangeSala}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="sala5" css={labelRatingCss} className={(5 <= sala ) ? "is-active" : ""}><Field className="form-control" type="radio" name="SalaComedor" value="5" id="sala5" onChange={onChangeSala}/><i
                      className="fas fa-star" aria-label="button"/></label>
                  </div>
                </div>
                <div css={ratingItemCss}>
                  <span>• Cocina</span>
                  <div>
                    <label htmlFor="cocina1" css={labelRatingCss} className={(1 <= cocina ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Cocina" value="1" id="cocina1" onChange={onChangeCocina}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="cocina2" css={labelRatingCss} className={(2 <= cocina ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Cocina" value="2" id="cocina2" onChange={onChangeCocina}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="cocina3" css={labelRatingCss} className={(3 <= cocina ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Cocina" value="3" id="cocina3" onChange={onChangeCocina}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="cocina4" css={labelRatingCss} className={(4 <= cocina ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Cocina" value="4" id="cocina4" onChange={onChangeCocina}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="cocina5" css={labelRatingCss} className={(5 <= cocina ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Cocina" value="5" id="cocina5" onChange={onChangeCocina}/><i
                      className="fas fa-star" aria-label="button"/></label>
                  </div>
                </div>

                <div css={ratingItemCss}>
                  <span>• Dormitorio</span>
                  <div>
                    <label htmlFor="dorm1" css={labelRatingCss} className={(1 <= dorm ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Dormitorio" value="1" id="dorm1" onChange={onChangeDorm}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="dorm2" css={labelRatingCss} className={(2 <= dorm ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Dormitorio" value="2" id="dorm2" onChange={onChangeDorm}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="dorm3" css={labelRatingCss} className={(3 <= dorm ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Dormitorio" value="3" id="dorm3" onChange={onChangeDorm}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="dorm4" css={labelRatingCss} className={(4 <= dorm ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Dormitorio" value="4" id="dorm4" onChange={onChangeDorm}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="dorm5" css={labelRatingCss} className={(5 <= dorm ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Dormitorio" value="5" id="dorm5" onChange={onChangeDorm}/><i
                      className="fas fa-star" aria-label="button"/></label>
                  </div>
                </div>
                <div css={ratingItemCss}>
                  <span>• Baño</span>
                  <div>
                    <label htmlFor="banio1" css={labelRatingCss} className={(1 <= banio ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Banio" value="1" id="banio1" onChange={onChangeBanio}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="banio2" css={labelRatingCss} className={(2 <= banio ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Banio" value="2" id="banio2" onChange={onChangeBanio}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="banio3" css={labelRatingCss} className={(3 <= banio ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Banio" value="3" id="banio3" onChange={onChangeBanio}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="banio4" css={labelRatingCss} className={(4 <= banio ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Banio" value="4" id="banio4" onChange={onChangeBanio}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="banio5" css={labelRatingCss} className={(5 <= banio ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Banio" value="5" id="banio5" onChange={onChangeBanio}/><i
                      className="fas fa-star" aria-label="button"/></label>
                  </div>
                </div>
                <div css={ratingItemCss}>
                  <span>• Terraza</span>
                  <div>
                    <label htmlFor="terraza1" css={labelRatingCss} className={(1 <= terraza ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Terraza" value="1" id="terraza1" onChange={onChangeTerraza}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="terraza2" css={labelRatingCss} className={(2 <= terraza ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Terraza" value="2" id="terraza2" onChange={onChangeTerraza}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="terraza3" css={labelRatingCss} className={(3 <= terraza ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Terraza" value="3" id="terraza3" onChange={onChangeTerraza}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="terraza4" css={labelRatingCss} className={(4 <= terraza ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Terraza" value="4" id="terraza4" onChange={onChangeTerraza}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="terraza5" css={labelRatingCss} className={(5 <= terraza ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Terraza" value="5" id="terraza5" onChange={onChangeTerraza}/><i
                      className="fas fa-star" aria-label="button"/></label>
                  </div>
                </div>
                <div css={ratingItemCss}>
                  <span>• Estudio</span>
                  <div>
                    <label htmlFor="estudio1" css={labelRatingCss} className={(1 <= estudio ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Estudio" value="1" id="estudio1" onChange={onChangeEstudio}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="estudio2" css={labelRatingCss} className={(2 <= estudio ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Estudio" value="2" id="estudio2" onChange={onChangeEstudio}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="estudio3" css={labelRatingCss} className={(3 <= estudio ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Estudio" value="3" id="estudio3" onChange={onChangeEstudio}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="estudio4" css={labelRatingCss} className={(4 <= estudio ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Estudio" value="4" id="estudio4" onChange={onChangeEstudio}/><i
                      className="fas fa-star" aria-label="button"/></label>
                    <label htmlFor="estudio5" css={labelRatingCss} className={(5 <= estudio ) ? "is-active" : ""}><Field className="form-control" type="radio" name="Estudio" value="5" id="estudio5" onChange={onChangeEstudio}/><i
                      className="fas fa-star" aria-label="button"/></label>
                  </div>
                </div>
              </div>

            </div>
            <div className="form-column">
              <Title text={titulo2} styleCss={titleCss}/>
              <div className="form-group">
                <Field className="form-control" type="text" name="NombresYApellidos" placeholder="Nombres y Apellidos*" />
                <ErrorMessage className="form-error" name="NombresYApellidos" component="div" />
              </div>
              <div className="form-group">
                <Field className="form-control" type="number" name="Edad" placeholder="Edad*" />
                <ErrorMessage className="form-error" name="Edad" component="div" />
              </div>
              <div className="form-group">
                <Field className="form-control" type="text" name="Email" placeholder="Correo*" />
                <ErrorMessage className="form-error" name="Email" component="div" />
              </div>
              <div className="form-group">
                <Field className="form-control" type="tel" name="Celular" placeholder="Celular*" />
                <ErrorMessage className="form-error" name="Celular" component="div" />
              </div>
              <div className="form-group">
                <Field className="form-control" type="tel" name="DistritoDeResidencia" placeholder="Distrito de residencia*" />
                <ErrorMessage className="form-error" name="DistritoDeResidencia" component="div" />
              </div>
              <div className="form-group">
                <label className="form-acept" htmlFor="Acepto_condiciones" css={formAceptCss}>
                  <Field className="accept-box" type="checkbox" name="Acepto_condiciones" id="Acepto_condiciones"/>&nbsp;&nbsp;&nbsp;He leído y acepto la {" "}<a href="/politica-de-privacidad/" target="_blank" rel="noreferrer">Política de privacidad</a>.</label>
                <ErrorMessage className="form-error mov-mb-13" name="Acepto_condiciones" component="div" />
                <label className="form-acept" htmlFor="Acepto_condiciones" css={formAceptCss}>
                  <Field className="accept-box" type="checkbox" name="Acepto_condiciones2" id="Acepto_condiciones2"/>&nbsp;&nbsp;&nbsp;He leído y acepto la transferencia de mis datos a las empresas del grupo empresarial Armando Paredes.</label>
                <ErrorMessage className="form-error mov-mb-13" name="Acepto_condiciones2" component="div" />
              </div>
              <button css={submitCss} type="submit" disabled={isSubmitting}>Enviar</button>
              { (envio === false && envio !== "") && <div className="">Error en el envio</div>}
              { (envio === true && envio !== "") && <div className="">Correo enviado</div>}
            </div>
          </FormStyle>
        )}
      </Formik>
    </div>
  );

}